<script setup lang="ts">
import { useRouter } from "vue-router"
import { computed, onUnmounted, ref, watch } from "vue"
import { useToggle } from "@vueuse/core"
import { useRouteParams } from "@vueuse/router"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import { LegalProfileRouteName } from "@/router"
import {
  type LegalEntityCreate,
  LegalEntityForm,
  DETAIL_REQUEST_ERROR_MESSAGE,
  useLegalEntityDetail,
  useLegalEntityStore
} from "@/modules/legal-entity"
import { ApiBadReqResponse, ApiForbiddenResponse } from "@/package/api-client"

const store = useLegalEntityStore()
const { isLegalEntityEditing, isEditingFinished } = storeToRefs(store)
const router = useRouter()
const profileId = useRouteParams<string>("id")
const [isOpened, toggleOpened] = useToggle(true)
const formRef = ref<InstanceType<typeof LegalEntityForm> | null>(null)

const openListingAction = () => router.push({ name: LegalProfileRouteName })

const { error, profile } = useLegalEntityDetail(profileId)
watch(
  error,
  async (err) => {
    if (err) {
      push.warning({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
      await openListingAction()
    }
  },
  { immediate: true }
)

const submitAction = async (requestData: LegalEntityCreate) => {
  try {
    if (profileId.value) {
      await store.editLegalEntity(profileId.value, requestData)
      push.success({
        message: "Информация сохранена"
      })
      toggleOpened(false)
      await store.updateBufferAfterEdit(profileId.value)
    }
  } catch (e) {
    console.log(e)
    if (e instanceof ApiBadReqResponse || e instanceof ApiForbiddenResponse) {
      push.error({
        message: e.message
      })
    } else {
      push.error({
        message: "Неизвестная ошибка, пожалуйста повторите позже или обратитесь в поддержку."
      })
    }
    isEditingFinished.value = false
  }
}

const isSubmitButtonDisabled = computed(() => {
  return isLegalEntityEditing.value || isEditingFinished.value
})

onUnmounted(() => {
  isEditingFinished.value = false
})
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Редактирование юридического лица"
    @onClose="openListingAction"
  >
    <LegalEntityForm ref="formRef" :profile="profile" type="edit" @submit="submitAction" />
    <template #footer>
      <ARow :gutter="[8, 8]">
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton block size="large" @click="() => toggleOpened(false)">Отмена</AButton>
        </ACol>
        <ACol :sm="24" :md="12" flex="1 1 50%">
          <AButton
            block
            type="primary"
            size="large"
            :loading="isSubmitButtonDisabled"
            @click="formRef?.submitAction"
          >
            Сохранить
          </AButton>
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
