<script setup lang="ts">
import type { FlatSubscription } from "../modules/subscription"

import { onMounted, ref, watch } from "vue"
import { push } from "notivue"

import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

import {
  SubscriptionPeriod,
  useGetCurrentSubscription,
  useGetSubscriptionTypes
} from "../modules/subscription"
import ListBlock from "../modules/subscription/components/ListBlock.vue"
import { usePaySubscription } from "../modules/subscription/hooks/use-pay-subscription"

const backgroundColor = [
  "linear-gradient(180deg, #969696 0%, #323231 100%)",
  "linear-gradient(180deg, #5297FF 0%, #0041D9 100%)",
  "linear-gradient(180deg, #95DE64 0%, #389E0D 100%)",
  "linear-gradient(180deg, #FFD666 0%, #D48806 100%)"
]

const borderColor = ["#646464", "#0057FF", "#52C41A", "#FAAD14"]

const subscriptions = ref<null | undefined | Array<FlatSubscription>>(null)

const open = ref(false)
const selectedSubscription = ref<null | FlatSubscription>(null)

const { subscriptionsData, getSubscriptions, SubscriptionsPending, SubscriptionsError } =
  useGetSubscriptionTypes()

const { paymentLink, getSubscriptionPaymentLink, paymentLinkPending, SubscriptionPaymentError } =
  usePaySubscription()

const {
  subscriptionCurrentData,
  getCurrentSubscription,
  SubscriptionCurrentPending,
  SubscriptionCurrentError
} = useGetCurrentSubscription()

onMounted(async () => {
  await getCurrentSubscription()
})

onMounted(async () => {
  await getSubscriptions()
  if (subscriptionsData) {
    subscriptions.value = subscriptionsData?.value?.data
      .map((product) =>
        product.tariffs.map((tariff) => ({
          name: product.name,
          cost: tariff.cost,
          description: tariff.description,
          id: tariff.id,
          marketing_discount: tariff.marketing_discount,
          period: tariff.period
        }))
      )
      .reverse()
      .flat()
  }
})

watch(SubscriptionsError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(SubscriptionCurrentError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(SubscriptionPaymentError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const getSubscriptionPeriod = (period: SubscriptionPeriod) => {
  switch (period) {
    case SubscriptionPeriod.OneMonth:
      return "1 месяц"
    case SubscriptionPeriod.ThreeMonths:
      return "3 месяца"
    case SubscriptionPeriod.SixMonths:
      return "6 месяцев"
    case SubscriptionPeriod.TwelveMonths:
      return "12 месяцев"
    default:
      return "Безлимитный период"
  }
}

const tariffBenefits = [
  "Работа с физическими лицами",
  "Работа с реестром операций",
  "Создание фактических и плановых операций",
  "Импорт excel выгрузок",
  "Работа с аналитикой",
  "Работа со справочниками",
  "Счета",
  "Проекты",
  "Статьи доходов и расходов"
]
const tariffBenefits2 = [
  "Подключение банков и импорт операций",
  "Контрагенты",
  "Товары и услуги",
  "Работа с юридическими лицами",
  "Работа с планированием и бюджетами",
  "Работа с отчетами"
]

const openModal = (subscription: FlatSubscription) => {
  selectedSubscription.value = subscription
  open.value = true
}

const paySubscription = async () => {
  await getSubscriptionPaymentLink(selectedSubscription.value?.id as number)

  const width = 850
  const height = 550

  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2

  window.open(
    paymentLink.value?.data?.auth_url as string,
    "subscription-payment-modal",
    `width=${width},height=${height},left=${left},top=${top}`
  )
}

const preOrder = () => {
  window.open(
    "https://forms.yandex.ru/cloud/66fa849c505690f54c121db2/",
    "_blank",
    "noopener,noreferrer"
  )
}
</script>

<template>
  <ATypographyTitle :level="3" :style="{ marginBottom: '16px', marginTop: '8px' }">
    Страница подписки
  </ATypographyTitle>
  <ARow :gutter="[24, 24]">
    <template v-if="SubscriptionCurrentPending || SubscriptionsPending">
      <ACol
        v-for="(item, key) in [...Array(4).keys()]"
        :key="key"
        class="gutter-row"
        :xs="24"
        :sm="12"
        :lg="6"
      >
        <ASkeletonButton class="skeleton" active block />
      </ACol>
    </template>

    <template v-else>
      <ACol
        v-for="(subscription, index) in subscriptions"
        :key="index"
        class="gutter-row"
        :xs="24"
        :sm="12"
        :lg="6"
      >
        <AFlex>
          <div
            class="gutter-box subscription-label"
            :style="{ background: backgroundColor[index] }"
          >
            <ATypographyTitle :level="3" :style="{ color: '#ffffff', fontWeight: 400 }">{{
              subscription?.name === "PRO" ? "Подписка ПРО" : "Подписка ФРИ"
            }}</ATypographyTitle>
          </div>
          <div
            class="gutter-box subscription-box"
            :style="{
              borderColor: borderColor[index]
            }"
          >
            <ATypographyTitle
              class="subscription-title"
              :level="1"
              :style="{
                background: subscription?.name === 'PRO' ? backgroundColor[index] : '#161615E0'
              }"
              >{{
                subscription?.name === "PRO" ? `${subscription?.cost} рублей` : "Бесплатно"
              }}</ATypographyTitle
            >
            <AFlex align="center">
              <ATypographyTitle :level="5" :style="{ fontWeight: 500, margin: 0 }">{{
                getSubscriptionPeriod(subscription.period as SubscriptionPeriod)
              }}</ATypographyTitle>
              <div v-if="subscription?.marketing_discount" class="subscription-discount">
                Скидка {{ subscription?.marketing_discount }}
              </div>
            </AFlex>
            <ListBlock :list="tariffBenefits" :style="{ marginTop: '24px' }" />
            <ListBlock
              :list="tariffBenefits2"
              :style="{ marginTop: '24px' }"
              :checked="subscription?.name !== 'FREE'"
            />
            <div
              v-if="subscription?.period === subscriptionCurrentData?.data?.tariff.period"
              :style="{
                marginTop: '32px',
                height: '40px',
                display: 'flex',
                alignItems: 'center'
              }"
            >
              <ATypographyText :style="{ fontSize: '16px' }">Текущая подписка</ATypographyText>
            </div>
            <div
              v-else-if="
                (subscription?.period === null &&
                  subscriptionCurrentData?.data?.tariff.period === 'P14D') ||
                (subscription?.period === null &&
                  subscriptionCurrentData?.data?.tariff.period === null)
              "
              :style="{
                marginTop: '32px',
                height: '40px',
                display: 'flex',
                alignItems: 'center'
              }"
            >
              <ATypographyText :style="{ fontSize: '16px' }">Текущая подписка</ATypographyText>
            </div>
            <AButton
              v-else
              block
              type="primary"
              size="large"
              :style="{ marginTop: '32px' }"
              @click="openModal(subscription)"
            >
              Подключить
            </AButton>
          </div>
        </AFlex>
      </ACol>
    </template>
  </ARow>
  <AModal v-model:open="open" :footer="null">
    <template #title>
      <ATypographyTitle :style="{ margin: 0 }" :level="3">
        {{
          selectedSubscription?.name === "PRO"
            ? "Подключить подписку ПРО"
            : "Подключить подписку ФРИ"
        }}
      </ATypographyTitle>
    </template>
    <AFlex align="center">
      <ATypographyTitle :level="5" :style="{ fontWeight: 500, margin: 0 }">{{
        selectedSubscription
          ? getSubscriptionPeriod(selectedSubscription.period as SubscriptionPeriod)
          : ""
      }}</ATypographyTitle>
      <div v-if="selectedSubscription?.marketing_discount" class="subscription-discount">
        Скидка {{ selectedSubscription?.marketing_discount }}
      </div>
    </AFlex>
    <ListBlock :list="tariffBenefits" :style="{ marginTop: '24px' }" />
    <ListBlock
      :list="tariffBenefits2"
      :style="{ marginTop: '24px' }"
      :checked="selectedSubscription?.name !== 'FREE'"
    />
    <AFlex justify="space-between" align="center" :style="{ marginTop: '20px' }">
      <ATypographyTitle :style="{ margin: 0 }" :level="4"> Стоимость подписки </ATypographyTitle>
      <ATypographyTitle :style="{ margin: 0 }" :level="4">{{
        selectedSubscription?.name === "PRO" ? `${selectedSubscription?.cost} рублей` : "Бесплатно"
      }}</ATypographyTitle>
    </AFlex>
    <AButton
      block
      type="primary"
      size="large"
      :style="{ marginTop: '16px' }"
      :loading="paymentLinkPending"
      disabled
      @click="paySubscription"
    >
      Оплатить
    </AButton>
    <AButton
      block
      type="primary"
      size="large"
      :style="{ marginTop: '16px' }"
      :loading="paymentLinkPending"
      @click="preOrder"
    >
      Оформить предзаказ
    </AButton>
    <div class="modal-footer">
      <ATypographyText :style="{ fontSize: '12px' }"
        >Нажимая кнопку "Оплатить", вы принимаете:</ATypographyText
      >
      <div class="modal-links">
        <ATypographyLink
          href="/docs/SAMO_privacy_policy.pdf"
          target="_blank"
          :style="{ fontSize: '12px' }"
          >Политика конфиденциальности,
        </ATypographyLink>
        <ATypographyLink href="/docs/SAMO_terms.pdf" target="_blank" :style="{ fontSize: '12px' }"
          >Пользовательское соглашение,
        </ATypographyLink>
        <ATypographyLink href="/docs/SAMO_details.pdf" target="_blank" :style="{ fontSize: '12px' }"
          >Реквизиты компании
        </ATypographyLink>
      </div>
    </div>
  </AModal>
</template>

<style>
.subscription-label {
  width: 50px;
  height: 260px;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  border-radius: 0 6px 6px 0;
  padding: 32px 7px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscription-box {
  border: 1px solid;
  width: 100%;
  border-radius: 0 6px 6px;
  padding: 24px 24px 32px 24px;
}

.subscription-title {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  margin-bottom: 4px !important;
}

.subscription-discount {
  margin-left: 16px;
  color: #ffffff;
  background: #d9363e;
  border-radius: 6px;
  padding: 1px 12px;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skeleton > span {
  height: 648px !important;
}
</style>
