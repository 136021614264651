import { computed } from "vue"

import {
  createMaxValidator,
  digitsIncludeValidator,
  phoneNumberValidator
} from "@/package/validators"
import type { LegalEntityInfoFields } from "@/modules/profile"

// eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
export const useRuleset = (formState: LegalEntityInfoFields) => {
  const ruleSet = computed(() => ({
    kbk: [
      {
        required: true,
        message: "Необходимо ввести КБК"
      },
      {
        asyncValidator: digitsIncludeValidator
      },
      {
        asyncValidator: createMaxValidator(20)
      }
    ],
    kpp: [
      {
        required: true,
        message: "Необходимо ввести КПП"
      },
      {
        asyncValidator: digitsIncludeValidator
      },
      {
        asyncValidator: createMaxValidator(9)
      }
    ],
    ogrnip: [
      {
        required: true,
        message: "Необходимо ввести ОГРНИП"
      },
      {
        asyncValidator: digitsIncludeValidator
      },
      {
        asyncValidator: createMaxValidator(15)
      }
    ],
    document_series: [
      {
        required: true,
        message: "Введите серию"
      }
    ],
    document_number: [
      {
        required: true,
        message: "Введите номер"
      }
    ],
    oktmo_first: [
      {
        required: true,
        message: "Введите данные ОКТМО"
      }
    ],
    oktmo_second: [
      {
        required: true,
        message: "Введите данные ОКТМО"
      }
    ],
    phone: [{ required: true, asyncValidator: phoneNumberValidator, trigger: "blur" }],
    email: [
      { type: "email", required: true, message: "Пожалуйста, введите e-mail", trigger: "blur" }
    ]
  }))

  return ruleSet
}
