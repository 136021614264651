<script setup lang="ts">
import type { LegalEntitySummary } from "../../interfaces"
import type { SorterResult } from "ant-design-vue/es/table/interface"

import { computed, h, onBeforeMount, ref } from "vue"
import { DashOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons-vue"
import { useInfiniteScroll, useToggle } from "@vueuse/core"
import { type TableProps } from "ant-design-vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { useBufferSelection } from "@/package/hooks"

import { useLegalEntityStore } from "../../store/use-legal-entity.store"

import { useColumns } from "./use-columns"

const emit = defineEmits(["openDetail", "openEdit", "openCreate", "openCopy"])
const customRow: TableProps["customRow"] = (data: LegalEntitySummary) => ({
  onClick: () => {
    emit("openDetail", data.id)
  }
})

const store = useLegalEntityStore()
const { idList, paginationTotal } = storeToRefs(store)
const {
  isAllSelected,
  selectedCount,
  selectedIdList,
  onSelect,
  onSelectAll,
  toggleAllSelected,
  unselectAllAction,
  currentModeKeys,
  excludeKeysMode
} = useBufferSelection(idList, paginationTotal)

const rowSelectConfig = computed(() => ({
  columnWidth: 32,
  onChange: onSelect,
  preserveSelectedRowKeys: true,
  selectedRowKeys: selectedIdList,
  onSelectAll
}))

const [isDeleting, toggleDeleting] = useToggle(false)
const deleteAction = (legalEntityId: string | number) => {
  DeleteConfirm({
    content:
      "Вы действительно хотите безвозвратно удалить запись? Также будет удален счет данной организации",
    onOk: async () => {
      toggleDeleting(true)
      try {
        await store.deleteOne(legalEntityId)
        push.success({ message: "Удалено" })
      } catch (e) {
        /* empty */
      }
      toggleDeleting(false)
    }
  })
}

const tableWrapper = ref<HTMLElement | null>(null)
const [isLoading, toggleLoading] = useToggle(false)
useInfiniteScroll(
  tableWrapper,
  async () => {
    toggleLoading(true)
    try {
      await store.loadBufferNextPage()
    } catch (e) {
      push.error({
        message:
          "Не удалось загрузить список Юр. лиц. Пожалуйста повторите позже или свяжитесь с поддержкой"
      })
    }
    toggleLoading(false)
  },
  {
    behavior: "smooth",
    throttle: 1000,
    distance: 1000,
    interval: 2000,
    canLoadMore: () =>
      !store.isLoadedFull && store.listingApiError === null && !store.isSortingLoading
  }
)

onBeforeMount(async () => {
  await store.getLegalEntitySorting()
})

defineExpose({
  selectedCount,
  isAllSelected,
  toggleAllSelected,
  unselectAllAction,
  currentModeKeys,
  excludeKeysMode
})
const { columns } = useColumns()
const isAdoptable = useAdoptable()
</script>

<template>
  <div
    ref="tableWrapper"
    :style="{
      overflow: 'auto',
      height: '100%',
      scrollBehavior: 'smooth',
      scrollbarGutter: 'stable'
    }"
  >
    <ATable
      size="large"
      :sticky="{
        offsetHeader: 0,
        offsetScroll: 0,
        getContainer: () => tableWrapper
      }"
      class="custom-table"
      :loading="isLoading"
      :columns="columns"
      :data-source="store.listing"
      :show-header="!isAdoptable"
      :row-selection="rowSelectConfig"
      :pagination="false"
      row-key="id"
      :custom-row="customRow"
      :show-sorter-tooltip="false"
      @change="
        async (pagination: any, filters: any, sorter: SorterResult) => {
          await store.setSortOrder(sorter)
          await store.saveLegalEntitySorting()
        }
      "
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <ADropdown
            :overlay-style="{ zIndex: 100 }"
            :trigger="['hover']"
            placement="bottomRight"
            arrow
          >
            <AButton :icon="h(DashOutlined)" type="text" @click.stop />
            <template #overlay>
              <AMenu>
                <AMenuItem
                  :icon="h(EditOutlined)"
                  @click="() => emit('openEdit', (record as LegalEntitySummary).id)"
                >
                  Редактировать
                </AMenuItem>
                <AMenuItem
                  danger
                  :disabled="isDeleting"
                  :icon="h(DeleteOutlined)"
                  @click="() => deleteAction((record as LegalEntitySummary).id)"
                >
                  Удалить
                </AMenuItem>
              </AMenu>
            </template>
          </ADropdown>
        </template>
      </template>
      <template #emptyText>
        <AEmptyListingPlaceholder
          create-text="Создать юридическое лицо"
          :show-create-button="store.isResourceEmpty || !store.paginationTotal"
          @openCreate="() => emit('openCreate')"
        />
      </template>
    </ATable>
  </div>
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}
.custom-table:deep(.ant-table-container)::after,
.custom-table:deep(.ant-table-container)::before {
  content: none !important;
}
</style>
