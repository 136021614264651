<script setup lang="ts">
import { computed, defineProps, type PropType } from "vue"

import SberIcon from "@/assets/icons/sbank.svg"
import AlphaIcon from "@/assets/icons/abank.svg"
import TIcon from "@/assets/icons/tbank.svg"

const props = defineProps({
  url: {
    type: String || undefined,
    required: true
  },

  bankType: {
    type: String as PropType<string | undefined>,
    required: true
  },

  bankName: {
    type: String as PropType<string | undefined>,
    required: true
  }
})

const icon = computed(() => {
  switch (props.bankType) {
    case "sber":
      return SberIcon
    case "alfabank_individual":
      return AlphaIcon
    case "alfabank_legal":
      return AlphaIcon
    case "t":
      return TIcon
    default:
      return null
  }
})

const openWindow = () => {
  sessionStorage.setItem("currentBank", props.bankName as string)
  sessionStorage.setItem("currentBankType", props.bankType as string)

  const width = 850
  const height = 550

  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2

  window.open(
    props.url as string,
    "bank-modal",
    `width=${width},height=${height},left=${left},top=${top}`
  )
}
</script>

<template>
  <AButton
    v-if="props.bankType === 'sber'"
    :icon="SberIcon"
    block
    size="large"
    class="bank-button sber"
    @click="openWindow"
    >Sberbank Button</AButton
  >
  <AButton
    v-if="props.bankType === 'alfabank_individual' || props.bankType === 'alfabank_legal'"
    block
    size="large"
    class="bank-button alpha"
    @click="openWindow"
    >Войти по Альфа ID
    <template #icon
      ><component :is="icon" :style="{ marginRight: '12px', width: '32px', height: '32px' }"
    /></template>
  </AButton>
  <AButton
    v-if="props.bankType === 't'"
    :icon="TIcon"
    block
    size="large"
    class="bank-button t"
    @click="openWindow"
    >Tinkoff Button</AButton
  >
</template>

<style scoped>
.bank-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alpha {
  border-color: #161615;
  height: 56px;
  font-family: "SFPro", serif;
  font-weight: 500;
}
</style>
