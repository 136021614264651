<script setup lang="ts">
import { computed, onMounted } from "vue"
import { useToggle } from "@vueuse/core"

import { useCitizenshipCode } from "../hooks/use-citizenship-code"

const {
  citizenshipCode: data,
  citizenshipCodePending: isLoading,
  error,
  getCitizenshipCode: execute
} = useCitizenshipCode()
const errorMessage = computed(() =>
  error.value
    ? "Не удалось загрузить информацию, пожалуйста повторите попытку или обратитесь в поддержку"
    : null
)
const options = computed(() =>
  data.value && errorMessage.value === null
    ? data?.value?.data
        .map(({ code, name }) => ({
          value: code,
          label: `${code}. ${name}`
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1))
    : []
)

const [isOpened, toggleOpen] = useToggle(false)

onMounted(() => {
  execute()
})
const retryAction = () => {
  toggleOpen()
  setTimeout(execute, 500)
}
</script>

<template>
  <ASelect
    v-model:open="isOpened"
    placeholder="Выберите гражданство"
    :loading="isLoading"
    :options="options"
    :status="errorMessage === null ? undefined : 'warning'"
    @dropdownVisibleChange="() => toggleOpen()"
  >
    <template #notFoundContent>
      <AEmpty>
        <template #description> {{ errorMessage }}</template>
        <AButton type="text" @click.stop="retryAction"> Повторить </AButton>
      </AEmpty>
    </template>
  </ASelect>
</template>
