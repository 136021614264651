<script setup lang="ts">
import { computed } from "vue"

const props = defineProps({
  subscription: {
    type: String,
    default: null
  },

  size: {
    type: String,
    default: "small"
  }
})

defineOptions({
  inheritAttrs: true
})

const badgeTitle = computed(() => (props.subscription === "PRO" ? "Про" : "Фри"))
</script>

<template>
  <AFlex class="badge" :class="{ large: props.size === 'large' }" justify="center" align="center">
    {{ badgeTitle }}
  </AFlex>
</template>

<style scoped>
.badge {
  background: linear-gradient(254.77deg, #5297ff 0%, #0041d9 100%);
  height: 18px;
  width: 39px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  line-height: normal;
}

.large {
  width: 73px;
  height: 36px;
  font-size: 24px;
  text-transform: uppercase;
}
</style>
