<script setup lang="ts">
import { computed, h, onMounted, type PropType, watch } from "vue"
import { get, set, useToggle } from "@vueuse/core"
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons-vue"
import { TreeSelect } from "ant-design-vue"

import { usePopupContainerGetter } from "@/package/ui-kit"

import { useOperationCategoryVariantsTree } from "../../hooks/use-operation-category-variants-tree"

defineProps({
  id: { type: Number, default: null },
  hasNoneOption: { type: Boolean, default: false },

  hideLink: {
    type: Boolean,
    default: false
  },

  excludedList: {
    type: Array as PropType<Array<number | string>>,
    default: () => []
  },

  excludedParent: {
    type: [String, Number] as PropType<number | string>,
    default: () => undefined
  },

  actionType: {
    type: String,
    default: undefined
  },

  style: {
    type: Object as PropType<Record<string, string>>,
    default: undefined
  },

  inputStyle: {
    type: [String, Object] as PropType<Record<string, string>>,
    default: "width: 95%"
  },

  maxTagCount: {
    type: Number,
    default: 2
  }
})

const selectedList = defineModel({
  type: Array as PropType<Array<number>>
})
const { isListingLoading, requestListing, listing, flatList } = useOperationCategoryVariantsTree()

onMounted(async () => await requestListing())

watch(listing, () => {
  if (listing?.value.length > 0) {
    const filteredSelectedIds = selectedList.value?.filter((selectedId) =>
      listing.value.find((listElement) => {
        return listElement.id === selectedId
      })
    )
    if (filteredSelectedIds?.length !== selectedList?.value?.length) {
      setTimeout(() => {
        selectedList.value = filteredSelectedIds
      }, 100)
    }
  }
})

const [isOpened, toggleOpen] = useToggle(false)

defineOptions({
  inheritAttrs: false
})
const popUpParent = usePopupContainerGetter()

const innerModel = computed({
  set: (newValue) => {
    set(selectedList, newValue as Array<number>)
  },
  get: () => {
    return (get(selectedList) ?? []).map((id) => {
      const r = get(flatList).get(id)
      return r?.id
    })
  }
})

const isAllChecked = computed(() => get(flatList).size === (get(selectedList) ?? []).length)
const checkAllAction = () => {
  const selecteableList = Array.from(get(flatList).values()).map(({ id }) => id)
  set(innerModel, selecteableList)
}
const cleanupAllAction = () => {
  set(selectedList, [])
}
const popupContainerGetter = usePopupContainerGetter()
const treeNodeFilter = (query: string, data: { id: number; name: string }) =>
  data.name.toLowerCase().includes(query.toLowerCase())
</script>

<template>
  <AFlex :gap="8" :style="style">
    <ATreeSelect
      v-bind="$attrs"
      v-model:value="innerModel"
      popup-class-name="operation-category-tree-select"
      :tree-line="{ showLeafIcon: false }"
      allow-clear
      :open="isOpened"
      placeholder="Выберите статью"
      size="large"
      show-search
      tree-node-filter-prop="name"
      virtual
      multiple
      :field-names="{ children: 'children', label: 'name', value: 'id' }"
      :filter-option="false"
      :tree-data="listing"
      :loading="isListingLoading"
      tree-data-simple-mode
      :filter-tree-node="treeNodeFilter"
      :show-checked-strategy="TreeSelect.SHOW_ALL"
      :get-popup-container="popupContainerGetter"
      :max-tag-count="maxTagCount"
      :style="inputStyle"
      @dropdownVisibleChange="toggleOpen"
    >
      <template #tagRender="{ label }">
        <ATag style="padding: 2px; border-radius: 6px; background-color: #ededed"
          ><ATypographyText ellipsis style="max-width: 70px; font-size: 16px">{{
            label
          }}</ATypographyText></ATag
        >
      </template>
    </ATreeSelect>
    <ATooltip
      :title="isAllChecked ? 'Снять все' : 'выбрать все'"
      placement="left"
      :get-popup-container="popUpParent"
      style="min-width: 35px"
    >
      <AButton
        v-if="isAllChecked"
        size="large"
        danger
        type="primary"
        :icon="h(DeleteOutlined)"
        @click="cleanupAllAction"
      />

      <AButton
        v-else
        size="large"
        type="primary"
        :icon="h(CheckOutlined)"
        @click="checkAllAction"
      />
    </ATooltip>
  </AFlex>
</template>

<style>
.operation-category-tree-select .ant-select-tree .ant-select-tree-indent-unit,
.operation-category-tree-select .ant-select-tree .ant-select-tree-switcher-leaf-line {
  opacity: 0;
}
</style>
